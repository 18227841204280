import { Avatar, HStack, Tbody, Td, Tooltip, Tr } from "@chakra-ui/react";
import React from "react";
import { FaTimes } from "react-icons/fa";
import { TbEdit } from "react-icons/tb";
import CustomModal from "../../CustomModal";
import AddCompanyModal from "../../AddCompanyModal";
import DeleteCompanyModal from "../../DeleteCompanyModal";
import { FaBan } from "react-icons/fa";
import { suspendCompany, toggleCompany } from "../../../services/companies";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const CompanyTableBody = ({ data, onClose }) => {
  const queryClient = useQueryClient();

  const { mutate: toggleEnableWallet, isLoading: isUpdateLoading } =
    useMutation({
      mutationFn: toggleCompany,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["companies"] });
        onClose();
      },
    });

  const handleToggleDisable = async (datum) => {
    const status = datum?.walletEnabled === true ? false : true;
    const payload = {
      _id: datum?._id,
      walletEnabled: status,
    };
    toggleEnableWallet(payload);
  };

  const { mutate: suspendAndUnsuspendCompany, isLoading: isSuspendLoading } =
    useMutation({
      mutationFn: suspendCompany,
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["companies"] });
        onClose();
      },
    });

  const handleSuspend = async (datum) => {
    const status = datum?.isSuspended ? false : true;
    const payload = {
      _id: datum?._id,
      isSuspended: status,
    };
    suspendAndUnsuspendCompany(payload);
  };
  return (
    <Tbody>
      {data?.map((datum) => (
        <Tr key={datum?._id} fontSize={"16px"}>
          <Td
            isTruncated
            maxWidth="170px !important"
            borderRight="1px solid #dadedf !important"
          >
            <Avatar
              size="xs"
              src={datum?.image}
              name={datum?.fullName}
              alt={`Avatar of ${datum?.fullName}`}
            />
          </Td>
          <Tooltip label={datum?.fullName}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
              cursor="pointer"
            >
              {datum?.fullName}
            </Td>
          </Tooltip>
          <Tooltip isTruncated maxWidth="170px !important" label={datum?.email}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
            >
              {datum?.email}
            </Td>
          </Tooltip>

          <Tooltip isTruncated label={datum?.phoneNumber}>
            <Td
              borderRight="1px solid #dadedf !important"
              isTruncated
              maxWidth="170px !important"
            >
              {datum?.phoneNumber}
            </Td>
          </Tooltip>

          <Td
            borderRight="1px solid #dadedf !important"
            textTransform={"capitalize"}
          >
            {datum?.address}
          </Td>

          <Tooltip label={datum?.activePlan}>
            <Td
              borderRight="1px solid #dadedf !important"
              isTruncated
              maxWidth="170px !important"
            >
              {datum?.activePlan}
            </Td>
          </Tooltip>
          <Td>
            <HStack spacing={"20px"}>
              <CustomModal
                label="Edit"
                icon={<TbEdit size="22px" color={"#154141"} />}
                header="Edit Company"
              >
                <AddCompanyModal text="Update Company" data={datum} />
              </CustomModal>

              <CustomModal
                label={datum.isSuspended ? "Unsuspend" : "Suspend"}
                icon={<FaTimes size="22px" color={"#154141"} />}
                header="Suspend Company"
              >
                <DeleteCompanyModal
                  content={
                    datum.isSuspended
                      ? "Are you sure you want to UNSUSPEND this Company"
                      : "Are you sure you want to SUSPEND this Company"
                  }
                  texts={datum.isSuspended ? "Unsuspend" : "Suspend"}
                  data={datum}
                  handleUpdate={() => handleSuspend(datum)}
                  status={datum?.isSuspended ? "unsuspended" : "suspended"}
                  loading={isSuspendLoading}
                />
              </CustomModal>

              <CustomModal
                label={datum?.walletEnabled === true ? "Disable" : "Enable"}
                icon={<FaBan size="22px" color={"#154141"} />}
                header={
                  datum?.walletEnabled === true
                    ? "Disable Company"
                    : "Enable Company"
                }
              >
                <DeleteCompanyModal
                  content={
                    datum?.walletEnabled === true
                      ? "Are you sure you want to DISABLE this company"
                      : "Are you sure you want to ENABLE this company"
                  }
                  texts={datum?.walletEnabled === true ? "Disable" : "Enable"}
                  data={datum}
                  handleUpdate={() => handleToggleDisable(datum)}
                  status={datum?.walletEnabled === true ? false : true}
                  loading={isUpdateLoading}
                />
              </CustomModal>
            </HStack>
          </Td>
        </Tr>
      ))}
    </Tbody>
  );
};

export default CompanyTableBody;
