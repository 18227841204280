import { errorNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";

export const getMonthlyAnalyticsData = async () => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_MONTHLY_ANALYTIC_GRAPH);
    return result?.data?.data;
  } catch (e) {
    if (e.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  }
};

export const getUserRequests = async () => {
  try {
    const response = await http.get(AUTH_ROUTES.GET_USER_REQUEST);
    return response?.data;
  } catch (e) {
    if (e.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  }
};

export const getDailyAnalytics = async () => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_DAILY_ANALYTICS);
    return result?.data?.data;
  } catch (e) {
    if (e.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  }
};

export const getMonthlyExpenses = async () => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_MONTHLY_EXPENSES);
    return result?.data?.data;
  } catch (e) {
    if (e.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  }
};

export const getExpenses = async () => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_EXPENSES);

    return result?.data?.data;
  } catch (e) {
    if (e.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  }
};

export const getAnalyticsOverview = async () => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_ANALYTICS_OVERVIEW);
    return result?.data?.data;
  } catch (e) {
    if (e.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
    return false;
  }
};

export const getDepartmentWithHighestExpenses = async (
  setDepartmentExpenses,
  setLoading
) => {
  try {
    const result = await http.get(AUTH_ROUTES.GET_DEPARTMENTS_PAYOUTS);
    setDepartmentExpenses(result?.data?.data);
    setLoading(false);
  } catch (e) {
    setLoading(false);
  }
};

export const refundWallet =
  (payload) =>
  (setLoading) =>
  (setRefresh) =>
  (setPaymentModal) =>
  async (setPaymentData) => {
    try {
      const {
        data: { data },
      } = await http.post(AUTH_ROUTES.REFUND_WALLET, payload);
      // successNotifier("Wallet refunded Successfully");
      setPaymentModal(true);
      // setLoading(false);
      setPaymentData(data);
      setRefresh((prev) => !prev);
    } catch (e) {
      errorNotifier(
        e.response?.data?.message || e.response?.data?.data?.message
      );
    } finally {
      setLoading(false);
      // setRefresh((prev) => !prev);
    }
  };
