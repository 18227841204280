export const companyTableHead = [
  "ID",
  "Company",
  "Email Address",
  "Phone Number",
  "Address",
  "Active Plan",
  "Action",
];
export const transactionsPayoutTableHead = [
  "Company",
  "Category",
  "Amount",
  "Date",
  "Status",
];

export const transactionsPayinTableHead = [
  "Company",
  "Category",
  "Amount",
  "Date",
  "Status",
];
