import { Box } from "@chakra-ui/react";
import { FormInputs } from "./FormInput";
import React, { useState } from "react";
import { CustomBtn } from "./CustomBtn";
import { createCompanies, updateCompanyDetails } from "../services/companies";
import { useMutation, useQueryClient } from "@tanstack/react-query";

function AddCompanyModal({ text, data, onClose }) {
  const queryClient = useQueryClient();
  const [formValue, setFormValue] = useState({
    fullName: data?.fullName,
    email: data?.email,
    phoneNumber: data?.phoneNumber,
    address: data?.address,
    // password: "zero",
    // spendingLimit: 300000,
    // wallet: 500000,
    password: "password",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue((prev) => ({ ...prev, [name]: value }));
  };

  const { mutate: createNewCompany, isLoading } = useMutation({
    mutationFn: createCompanies,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["companies"] });
      onClose();
    },
  });

  const { mutate: updateCompany, isLoading: isUpdateLoading } = useMutation({
    mutationFn: updateCompanyDetails,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["companies"] });
      onClose();
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = { ...formValue, _id: data?._id };
    if (data?._id) {
      updateCompany(payload);
    } else {
      createNewCompany(payload);
    }
  };

  return (
    <Box>
      <FormInputs
        label="Company Name"
        type="text"
        name="fullName"
        value={formValue.fullName}
        handleChange={handleChange}
      />
      <FormInputs
        label="Email Address"
        type="email"
        name="email"
        value={formValue.email}
        handleChange={handleChange}
      />
      <FormInputs
        label="Phone Number "
        type="text"
        name="phoneNumber"
        value={formValue.phoneNumber}
        handleChange={handleChange}
      />
      <FormInputs
        label="Address"
        type="text"
        name="address"
        value={formValue.address}
        handleChange={handleChange}
      />
      <Box align="center">
        <CustomBtn
          text={text}
          handleClick={handleSubmit}
          loading={isLoading || isUpdateLoading}
          disabled={
            !formValue.fullName ||
            !formValue.email ||
            !formValue.address ||
            !formValue.phoneNumber
          }
        />
      </Box>
    </Box>
  );
}

export default AddCompanyModal;
