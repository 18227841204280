import jwtDecode from "jwt-decode";
import { errorNotifier, successNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";

export const loginUser = async (payload) => {
  try {
    const {
      data: { data },
    } = await http.post(AUTH_ROUTES.LOGIN, payload);
    // setLoading(false);
    const token = data?.token;
    sessionStorage.setItem("#EXPTR#SU#PADMIN", token);
    const decoded = jwtDecode(token);
    if (decoded.type === "superAdmin") {
      window.location.reload();
      return data;
    } else {
      errorNotifier("Not Authorized");
    }
  } catch (e) {
    // setLoading(false);
    errorNotifier(e.response?.data?.message || e.response?.data?.data?.message);
  }
};

export const sendOtp = async (payload, setLoading, navigateTo) => {
  try {
    await http.post(AUTH_ROUTES.SEND_OTP, payload);
    setLoading(false);
    navigateTo("sendOTP");
    successNotifier("Check your email for OTP");
    // return data;
  } catch (e) {
    setLoading(false);
    errorNotifier(e.response?.data?.message || e.response?.data?.data?.message);
  }
};

export const verifyOTP = async (payload, setLoading, navigateTo) => {
  try {
    const {
      data: { data },
    } = await http.post(AUTH_ROUTES.VERIFY_OTP, payload);
    setLoading(false);
    navigateTo("verifyOTP");
    return data;
  } catch (e) {
    setLoading(false);
    errorNotifier(e.response?.data?.message || e.response?.data?.data?.message);
  }
};

export const resetPassword = async (payload, setLoading, navigateTo) => {
  try {
    const {
      data: { data },
    } = await http.post(AUTH_ROUTES.RESET_PASSWORD, payload);
    setLoading(false);
    navigateTo("/");
    return data;
  } catch (e) {
    setLoading(false);
    errorNotifier(e.response?.data?.message || e.response?.data?.data?.message);
  }
};
