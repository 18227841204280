import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  ChakraProvider,
  createStandaloneToast,
  extendTheme,
} from "@chakra-ui/react";
import { QueryClient } from "@tanstack/query-core";
import { QueryClientProvider } from "@tanstack/react-query";

const { toast, ToastContainer } = createStandaloneToast();

const theme = extendTheme({
  colors: {
    white: "#FFF",
    dark: "#000",
    grey: "#F9FBFB",
    primary: "#154141",
    btnbg: "#4A9290",

    tablegray: {
      500: "#F9FBFB",
    },
    lightgreen: {
      500: "#3C7B79",
    },
    gray: {
      500: "#F8F8FE",
    },
  },
});

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <App />
        <ToastContainer />
      </ChakraProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

export const errorNotifier = (errorMessage) => {
  return toast({
    // title: "Error",
    description:
      typeof errorMessage === "string" ? errorMessage : "SOMETHING WENT WRONG",
    status: "error",
    duration: 5000,
    isClosable: true,
    position: "top-right",
  });
};

export const successNotifier = (info) => {
  return toast({
    // title: "Success",
    description: info,
    status: "success",
    duration: 5000,
    isClosable: true,
    position: "top",
  });
};
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
