import { createContext, useState } from "react";
import { getUserProfile } from "./getUserProfile";
import { useQuery } from "@tanstack/react-query";
// import { getCompanyDetails } from "../services/accountSettings";

export const UserProfileContext = createContext({});

export const UserProfileContextProvider = ({ children }) => {
  const [state, setState] = useState({ signin: true });

  const dispatch = (incoming) => {
    setState((prev) => ({ ...prev, ...incoming }));
  };

  const { data, isLoading } = useQuery(
    {
      queryFn: getUserProfile,
      queryKey: ["user"],
    },
    {
      onSuccess: () => {
        setState((prev) => ({
          ...prev,
          loading: isLoading,
          userInfo: data,
        }));
      },
    }
  );

  return (
    <UserProfileContext.Provider
      value={{
        dispatch,
        state: { signIn: true, loading: isLoading, userInfo: { ...data } },
      }}
    >
      {children}
    </UserProfileContext.Provider>
  );
};
