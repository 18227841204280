import React from 'react';
import { Box, Flex, useDisclosure, Heading } from '@chakra-ui/react';
import { CustomBtn } from '../components/CustomBtn';
import ContactUsModal  from '../components/ContactUs';
import {getSupportTicket, resolveTicket} from "../services/support"
import { useQuery, useMutation, useQueryClient  } from "@tanstack/react-query";
import FullPageLoader from '../components/FullPageLoader';
import SupportCard from '../components/SupportCard';

const Support = () => {
    const queryClient = useQueryClient();
  const { onOpen, isOpen, onClose } = useDisclosure();

  const { data, isLoading } = useQuery({
    queryKey: ["support"],
    queryFn: getSupportTicket,
  });

  const { mutate: mutateResolveTickets, isLoading: isResolveLoading } = useMutation({
    mutationFn: resolveTicket,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["support"] });
      onClose();
    },
  });
  const handleResolve = async (ticketId) => {
    const payload = { ticketId, status: 'closed'}
    mutateResolveTickets( payload);
  };
  
  
  return isLoading ? (
    <FullPageLoader />
    ) : (
    <Box>
      <Flex justifyContent={"space-between"}>
      <Heading
          as="h2"
          fontSize={["22px", "22px", "30px"]}
          mb={["20px", "20px", "0px"]}
        >
          Support Messages
        </Heading>
        <CustomBtn text="Send Broadcast" handleClick={onOpen} />
        </Flex>
      <SupportCard 
      data={data} 
      handleResolve={handleResolve}
      ResolveLoading={isResolveLoading}
      onClose={onClose}
      />
      <ContactUsModal {...{ isOpen, onClose }} />
    </Box>
  );
}

export default Support;


