import { Flex, Select, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import BoxShadowContainer from "./BoxShadowContainer";
import {
  getDailyAnalytics,
  getMonthlyAnalyticsData,
} from "../../services/overview";
import dayjs from "dayjs";
import { LineChart } from "./charts/LineChart";

export const AverageRevenueCard = () => {
  const [duration, setDuration] = useState("monthy");

  useState({});
  const [graphData, setGraphData] = useState({
    xAxis: [],
    yAxis: [],
  });

  const { data: dailyAnalytics } = useQuery({
    queryKey: ["dailyAnalytics"],
    queryFn: getDailyAnalytics,
  });
  const { data: monthlyAnalyticsData } = useQuery({
    queryKey: ["monthlyAnalyticsData"],
    queryFn: getMonthlyAnalyticsData,
  });

  useEffect(() => {
    setGraphData({
      xAxis: monthlyXAxis,
      yAxis: monthlyYAxis,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const monthlyXAxis =
    monthlyAnalyticsData?.totalFundsDisbursed &&
    Object.keys(monthlyAnalyticsData?.totalFundsDisbursed);
  const monthlyYAxis =
    monthlyAnalyticsData?.totalFundsDisbursed &&
    Object.values(monthlyAnalyticsData?.totalFundsDisbursed);

  const dailyXAxis =
    dailyAnalytics?.totalFundDisbursed &&
    Object.keys(dailyAnalytics?.totalFundDisbursed);
  const dailyYAxis =
    dailyAnalytics?.totalFundDisbursed &&
    Object.values(dailyAnalytics?.totalFundDisbursed);

  const handleChange = (e) => {
    const { value } = e.target;
    setDuration(value);
    if (value === "daily") {
      setGraphData({
        xAxis: dailyXAxis,
        yAxis: dailyYAxis,
      });
    } else {
      setGraphData({
        xAxis: monthlyXAxis,
        yAxis: monthlyYAxis,
      });
    }
  };

  return (
    <BoxShadowContainer w={["100%", "100%", "100%"]} color={"#393838"}>
      <Flex justify={"space-between"} align={"center"}>
        <Text color={"#000000"} fontSize={"1.6rem"} fontWeight={"semibold"}>
          Average Expenses
        </Text>
        <Select
          w={"200px"}
          placeholder="Select a duration"
          color={"#636161"}
          bg="#FFFFFF"
          borderColor={"#3C7B79"}
          onChange={(e) => handleChange(e)}
        >
          <option value={"monthly"}>Monthly</option>
          <option value={"daily"}>Daily</option>
          {/* <option value={"yearly"}>Yearly</option> */}
        </Select>
      </Flex>
      <Text
        color={"#575555"}
        fontSize={"1rem"}
        fontWeight={"semibold"}
        pt={"7px"}
      >
        Basic Statistic Presenting Daily, Weekly, Monthly Expenses
      </Text>
      <Text
        color={"#575555"}
        fontSize={"0.8rem"}
        fontWeight={"semibold"}
        display={"flex"}
        justifyContent={"flex-end"}
        pt={"7px"}
      >
        {duration === "monthly" && graphData?.xAxis?.[0]
          ? `From ${graphData?.xAxis?.[0] || "Date"} to ${dayjs().format(
              "MMM"
            )}`
          : `From ${graphData?.xAxis?.[0] ?? "--"} to ${dayjs().format("MMM")}`}
      </Text>
      <LineChart
        xAxisData={graphData?.xAxis || monthlyXAxis}
        yAxisData={graphData?.yAxis || monthlyYAxis}
      />
    </BoxShadowContainer>
  );
};
