import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import FilterSection from "../components/FilterSection";
import CustomTab from "../components/tabs/CustomTab";

import {
  filterTransactions,
  getPayinTransactions,
  getPayoutTransactions,
  searchTransactions,
} from "../services/transactions";
import { useQuery } from "@tanstack/react-query";
import FullPageLoader from "../components/FullPageLoader";

function TransactionPage() {
  const [tabName, setTabName] = useState("Pay Out");
  const [searchedTransactions, setSearchedTransactions] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const { data: payoutTransactions } = useQuery({
    queryKey: ["payoutTransactions"],
    queryFn: getPayoutTransactions,
  });

  const { data: payinTransactions, isLoading } = useQuery({
    queryKey: ["payinTransactions"],
    queryFn: getPayinTransactions,
  });

  const transactions =
    tabName === "Pay Out" ? payoutTransactions : payinTransactions;
  const tabTitle = tabName === "Pay Out" ? "payOut" : "payIn";
  const transactionsData =
    searchedTransactions.length > 0 ? searchedTransactions : transactions;

  //("TRANSACTIONS", searchedTransactions);

  const handleSearch = async (e) => {
    const keyword = e.target.value;
    await searchTransactions(keyword, tabTitle, setSearchedTransactions);
  };

  const handleSearchByDate = async (e) => {
    await filterTransactions(
      setSearchedTransactions,
      startDate,
      e.target.value,
      tabTitle
    );
  };

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <Box w="100%">
      <FilterSection
        header={"Transactions"}
        placeholder={"Transactions"}
        startDate={startDate}
        endDate={endDate}
        setEndDate={setEndDate}
        setStartDate={setStartDate}
        handleSearch={handleSearch}
        handleSearchByDate={handleSearchByDate}
        // transactionsData={transactionsData}
      />
      <CustomTab
        transactions={transactionsData}
        setTabName={setTabName}
        payin={payinTransactions}
      />
    </Box>
  );
}

export default TransactionPage;
