export const transactionCsvHeaders = [
  {
    key: "department",
    label: "Departments",
  },
  {
    key: "category",
    label: "Catergories",
  },
  {
    key: "amount",
    label: "Amounts",
  },
  {
    key: "date",
    label: "Date",
  },
  {
    key: "status",
    label: "Status",
  },
];
export const companyCsvHeaders = [
  // {
  //   key: "id",
  //   label: "Id",
  // },
  {
    key: "company",
    label: "Company",
  },
  {
    key: "email",
    label: "Email Address",
  },
  {
    key: "phoneNumber",
    label: "Phone Number",
  },
  {
    key: "address",
    label: "Address",
  },
  {
    key: "activePlan",
    label: "Active Plan",
  },
];
