import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Flex, Text } from "@chakra-ui/react";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
    y: {
      ticks: {
        stepSize: 10,
      },
    },
  },
};

export function LineChart({ xAxisData, yAxisData }) {
  if (!xAxisData || xAxisData === 0) {
    return (
      <Flex justify={"center"} align={"center"} h={"400px"} bg="#ECECEE">
        <Text fontWeight={"semibold"} fontSize={"22px"}>
          No data available
        </Text>
      </Flex>
    );
  }
  const data = {
    labels: xAxisData,
    datasets: [
      {
        label: "Amount",
        data: yAxisData,
        fill: true,
        backgroundColor: "#C7DBDB",
        borderColor: "#70A19F",
        borderWidth: 1.5,
        pointBorderColor: "#70A19F",
        pointBorderWidth: 4,
        tension: 1,
      },
    ],
  };
  return (
    <div>
      <Line options={options} data={data} />
    </div>
  );
}
