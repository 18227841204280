import React from "react";
import { Box, Table } from "@chakra-ui/react";
import { companyTableHead } from "../../constants/table.constant";
import TableHead from "../TableHead";
import CompantTableBody from "./CompantTableBody";

const CompanyTable = ({ data }) => {
  return (
    <Box className="table-container" overflowX="scroll" width={"100%"}>
      <Table
        variant="striped"
        colorScheme="gray"
        bg="#fff"
        borderRadius={"5px"}
        // width={["100% !important"]}
        // overflowX={["scroll"]}
      >
        <TableHead tableHeadData={companyTableHead} />
        <CompantTableBody data={data} />
        
      </Table>
    </Box>

  );
};

export default CompanyTable;
