import { useState } from "react";
import { Box, Flex, Heading } from "@chakra-ui/react";
import SearchField from "../components/SearchField";
import { CustomBtn } from "../components/CustomBtn";
import { AiOutlinePlus } from "react-icons/ai";
import CompanyTable from "../components/table/company/CompanyTable";
import CustomModal from "../components/CustomModal";
// import CSVDownload from "../components/CSVDownload";
import { companyCsvHeaders } from "../components/constants/csvHeaders.constants";
import { companyCsv } from "../utils/csvContents";
import AddCompanyModal from "../components/AddCompanyModal";
import { getAllCompanies } from "../services/companies";
import { useQuery } from "@tanstack/react-query";
import FullPageLoader from "../components/FullPageLoader";
import Notification from "../components/Notification";
import { searchCompany } from "../services/companies";
import { CSVLink } from "react-csv";
import { BiDownload } from "react-icons/bi";

const Companies = ({ headers, currentRecord, color, responseData, text }) => {
  const [filteredCompany, setFilteredCompany] = useState([]);
  const [, setSearchLoading] = useState(false);
  const [notFoundError, setNotFoundError] = useState(false);

  const { data: queryData, isLoading } = useQuery({
    queryKey: ["companies"],
    queryFn: getAllCompanies,
  });

  const companyData = filteredCompany.length > 0 ? filteredCompany : queryData;
  const handleSearch = async (e) => {
    const keyword = e.target.value;
    await searchCompany(
      setFilteredCompany,
      keyword,
      setSearchLoading,
      setNotFoundError
    );
  };

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <Box w="100%">
      {notFoundError && <Notification text="No Company Found" />}
      <Flex
        width="100%"
        justify={["start", "start", "space-between"]}
        alignItems={["start", "start", "center"]}
        direction={["column", "column", "row"]}
        mb="30px"
      >
        <Heading
          as="h2"
          fontSize={["22px", "22px", "30px"]}
          mb={["20px", "20px", "0"]}
        >
          Company List
        </Heading>
        <Flex
          direction={["column-reverse", "column-reverse", "column", "row"]}
          width={["100%", "100%", "100%", "84%", "75%"]}
          justify="space-between"
          align="center"
          gap=".5rem"
        >
          <Box
            width={["100%", "100%", "47%"]}
            my={["10px", "10px", "10px", ""]}
            pr={["0px", "0px", "0px", "45px", "0px"]}
          >
            <SearchField
              placeholder="Company Name/Email"
              handleSearch={handleSearch}
            />
          </Box>

          <Flex
            gap="45px"
            align={"center"}
            flexDir={["column", "column", "row"]}
          >
            <CustomModal
              header={`Add New Company`}
              icon={
                <CustomBtn
                  text="Add Company"
                  childComp={<AiOutlinePlus color="#fff" />}
                />
              }
            >
              <AddCompanyModal text="Add Company" />
            </CustomModal>
            <CSVLink
              headers={companyCsvHeaders}
              data={companyCsv(queryData)}
              text={text}
              className="download-hidden"
            >
              <CustomBtn
                bg="transparent"
                color="#3C7B79"
                text="Download CSV"
                childComp={
                  <BiDownload size={22} color={"#154141"} cursor={"pointer"} />
                }
              />
            </CSVLink>
          </Flex>
        </Flex>
      </Flex>
      <CompanyTable data={companyData} />
    </Box>
  );
};

export default Companies;
