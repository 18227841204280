import dayjs from "dayjs";
import { formatToNaira } from "./numberFormat";

export const transactionCsv = (data) => {
  return data?.map((data) => {
    return {
      department: data?.department || "Ope",
      category: data?.categories || "ope",
      amount: formatToNaira(data?.amount) || "5000",
      date: dayjs(data?.updatedAt).format("MMM, DD YYYY") || "43",
      status: data?.status || "active",
    };
  });
};
export const companyCsv = (data) => {
  return data?.map((data) => {
    return {
      // id: data?.id || "N/A",
      company: data?.fullName || "N/A",
      email: data?.email || "N/A",
      phoneNumber: String(data?.phoneNumber || "N/A"),
      address: data?.address || "N/A",
      activePlan: data?.activePlan || "N/A",
    };
  });
};