import { Box, Flex, HStack, Heading, Select, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import PayinPayoutChart from "./charts/PayinPayoutChart";
import BoxShadowContainer from "./BoxShadowContainer";
import {
  getDailyPayin,
  getDailyPayout,
  getMonthlyPayout,
  getMontlyPayin,
} from "../../services/analytics";

export const PayinPayoutCard = () => {
  const [selectedOption, setSelectedOption] = useState("monthly");

  const { data: monthlyPayin } = useQuery({
    queryKey: ["monthlyPayin"],
    queryFn: getMontlyPayin,
  });
  const { data: dailyPayin } = useQuery({
    queryKey: ["dailyPayin"],
    queryFn: getDailyPayin,
  });
  const { data: monthlyPayout } = useQuery({
    queryKey: ["monthlyPayout"],
    queryFn: getMonthlyPayout,
  });
  const { data: dailyPayout } = useQuery({
    queryKey: ["dailyPayout"],
    queryFn: getDailyPayout,
  });


  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  return (
    <BoxShadowContainer w={["100%", "100%", "100%"]} color={"#393838"}>
      <Flex
        justify={["center", "center", "space-between"]}
        w={"100%"}
        align={"center"}
        flexWrap={["wrap", "wrap", "nowrap"]}
      >
        <Heading fontSize={"25px"}>Pay In - Pay Out</Heading>
        <HStack>
          <Flex align={"center"} pb={"10px"}>
            <Box
              h="11px"
              w="13px"
              rounded={"sm"}
              bg={"#6C87A3"}
              mr="10px"
            ></Box>
            <Flex justify={"space-between"} w="100%">
              <Text>{"Pay In"}</Text>
            </Flex>
          </Flex>
          <Flex align={"center"} pb={"10px"}>
            <Box
              h="11px"
              w="12px"
              rounded={"sm"}
              bg={"#3C7B79"}
              mr="10px"
            ></Box>
            <Flex justify={"space-between"} w="100%">
              <Text>{"Pay Out"}</Text>
            </Flex>
          </Flex>
        </HStack>
        <Select
          placeholder="Monthly"
          color={"#3C7B79"}
          borderColor={"#3C7B79"}
          w={"200px"}
          value={selectedOption}
          onChange={handleOptionChange}
        >
          <option value="monthly">Monthly</option>
          <option value="daily">Daily</option>
        </Select>
      </Flex>

      {!monthlyPayin && !monthlyPayout && !dailyPayin && !dailyPayout ? (
        <Flex justify={"center"} align={"center"} h={"400px"} bg="#ECECEE">
          <Text fontWeight={"semibold"} fontSize={"22px"}>
            No data available
          </Text>
        </Flex>
      ) : (
        <PayinPayoutChart
          selectedOption={selectedOption}
          monthlyPayin={monthlyPayin}
          dailyPayin={dailyPayin}
          monthlyPayout={monthlyPayout}
          dailyPayout={dailyPayout}
        />
      )}
    </BoxShadowContainer>
  );
};
