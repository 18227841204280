import React from "react";
import { Flex, Table, Text } from "@chakra-ui/react";
import { overviewTableHead } from "../../table.constant";
import TableHead from "../table/TableHead";
import OverviewTableBody from "../../components/overview/OverviewTableBody";

function OverviewTable({ overview, isLoading }) {
  return isLoading ? (
    <Flex
      justifyContent={"center"}
      alignItems="center"
      bg="#fff"
      h="250px"
      w="100%"
    >
      <Text>Loading...</Text>
    </Flex>
  ) : (
    <Table variant="striped" colorScheme="gray" bg="#fff" borderRadius={"5px"}>
      <TableHead tableHeadData={overviewTableHead} />
      <OverviewTableBody overview={overview?.data} />
    </Table>
  );
}

export default OverviewTable;
