export const AUTH_ROUTES = {
  LOGIN: "/admin/login/super-admin",
  GET_LOGGED_IN_ADMIN: "/admin/me",
  SEND_OTP: "/auth/send-otp",
  VERIFY_OTP: "/auth/verify/otp",
  RESET_PASSWORD: "auth/admin-password-reset",

  //ANALYTICS
  GET_MONTHLY_CATEGORY_EXPENSE: `/analytics/category/expenses`,
  GET_DAILY_CATEGORY_EXPENSE: `/analytics/category/daily/expenses`,
  GET_EXPENSE_PER_ITEM: `/analytics/items`,
  GET_MONTHLY_PAYIN: `/analytics/monthly/revenue?status=confirmed`,
  GET_DAILY_PAYIN: `/analytics/daily/revenue?status=confirmed`,
  GET_MONTHLY_PAYOUT: `/analytics/monthly-analytics`,
  GET_DAILY_PAYOUT: `/analytics/daily-analytics`,
  GET_TOP_SPENDERS: "/analytics/top/spender",
  GET_MONTHLY_SPENDERS_LIST: "/analytics/general/data",
  GET_ANNUAL_PAYOUTSTOVENDORS: `/analytics/annual-vendor-record`,
  GET_MONTHLY_PAYOUTSTOVENDORS: `/analytics/vendors`,

  // COMPANIES
  GET_ALL_COMPAIES: "/company/fetch?sort=desc",
  CREATE_COMPANY: "/company",
  UPDATE_COMPANY_DETAILS: (companyId) => `/company/update/${companyId}`,
  GET_COMPANY_DETAILS: (companyId) => `/company/fetch?id=${companyId}`,
  SEARCH_COMPANY: (keyword) => `/company/search?search=${keyword}`,
  SUSPEND_COMPANY: (companyId) => `/admin/company/status/${companyId}`,
  TOGGLE_COMPANY: (companyId) => `/company/wallet-toggle/${companyId}`,

  //NOTIFICATIONS
  GET_NOTIFICATIONS: "/notification?sort=desc",
  READ_NOTIFICATION: (id) => `/notification/${id}`,

  //OVERVIEW
  GET_DAILY_ANALYTICS: "/analytics/daily-analytics",
  GET_EXPENSES: "/analytics/expenses",
  GET_ANALYTICS_OVERVIEW: "/analytics/overview",
  GET_MONTHLY_ANALYTIC_GRAPH: "/analytics/monthly-analytics",
  GET_MONTHLY_EXPENSES: "/analytics/monthly/expenses",
  GET_DEPARTMENT_EXPENSES: "/analytics/department/expenses",
  REFUND_WALLET: "/transaction/initiate",
  GET_USER_REQUEST: "/request/user-request",

  // TRANSACTIONS
  GET_PAYOUT_TRANSACTIONS: `/transaction?sort=desc&paymentFor=payOut`,
  GET_PAYIN_TRANSACTIONS: `/transaction?sort=desc&paymentFor=payIn`,
  FILTER_TRANSACTIONS: (from, to, paymentFor) =>
    `/transaction/search?endDate=${from}&startDate=${to}&paymentFor=${paymentFor}`,
  SEARCH_TRANSACTIONS_BY_KEYWORD: (keyword, paymentFor) =>
    `/transaction/search?search=${keyword}&paymentFor=${paymentFor}`,

  // ACCOUNT_SETTING
  UPDATE_ADMIN_ACCOUNT: (adminId) => `/admin/update/${adminId}`,
  UPLOAD_IMAGE: `/admin/image/upload`,

  // SUBSCRIPTION
  CREATE_SUBSCRIPTION: `/subscription/create`,
  GET_SUBSCRIPTION: `/subscription`,
  DELETE_SUBSCRIPTION: (id) => `/subscription/delete/${id}`,
  UPDATE_SUBSCRIPTION: (id) => `/subscription/update/${id}`,

  // SUPPORT
  GET_SUPPORT_TICKET: "/support?sort=desc",
  RESPONSE_SUPPORT_TICKET: `/support/response`,
  DELETE_SUPPORT_TICKET: (id) =>`/support/delete/${id}`,
  RESOLVE_TICKET: (id) =>`/support/close/${id}`,

  // BROADCAST
  SEND_BROADCAST: `/broadcast/send`,
};
