import { useState } from "react";
import { CustomBtn } from "../CustomBtn";
import { FormInputs } from "../FormInput";
import {
  FormControl,
  Box,
  Stack,
  Flex,
  Text,
  Spinner,
  Input,
  Image,
  FormLabel,
} from "@chakra-ui/react";
import { MdDriveFolderUpload } from "react-icons/md";
import { updateAdminAccount, uploadImage } from "../../services/accountSetting";
import { useMutation } from "@tanstack/react-query";
import { useQueryClient } from "@tanstack/react-query";
import { useGetState } from "../../contexts/useGetState";

function AccountDetails() {
  const queryClient = useQueryClient();
  const { state } = useGetState();
  const [imageLoading, setImageLoading] = useState(false);

  const [formData, setFormData] = useState({
    fullName: state?.userInfo?.fullName.trim(),
    email: state?.userInfo?.email,
  });

  const handleChangePic = async (e) => {
    setImageLoading(true);
    const image = e.target.files?.[0];
    const formData = new FormData();
    formData.append("image", image);
    changeImageMutation.mutate(formData);
  };
  const changeImageMutation = useMutation(uploadImage, {
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
      setImageLoading(false);
    },
    onError: () => {},
  });

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: updateAdminAccount,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["user"] });
    },
    onError: () => {},
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const payload = { ...formData, _id: state.userInfo?._id };
    mutate(payload);
  };

  return (
    <Box bg="white" w="100%" pt={"10px"}>
      <Stack
        width="100%"
        direction="row"
        alignItems="center"
        spacing={"-10"}
        pl="30px"
      >
        <Box width={["30%", "30%", "10%"]} position="relative">
          <Input
            type="file"
            name="input"
            id="input"
            width={0}
            height={0}
            visibility="hidden"
            overflow="hidden"
            onChange={handleChangePic}
          />
          <FormLabel
            htmlFor="input"
            display="inline-block"
            backgroundColor={"#FFF"}
            position="relative"
          >
            <Image
              src={state?.userInfo?.image}
              alt="User"
              w={["60px", "60px", "90px"]}
              h={["60px", "60px", "90px"]}
              borderRadius="50%"
            />
            {imageLoading && (
              <Box position={"absolute"} top="5" left="5">
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </Box>
            )}
          </FormLabel>
        </Box>

        <Box width={["60%", "60%", "80%"]}>
          <Text fontWeight={"Bold"} color="#000" textTransform="capitalize">
            {state?.userInfo?.fullName}
          </Text>
          <Text fontWeight={"Trenda-Regular"} color="#686868" fontSize="14px">
            {state?.userInfo?.email}
          </Text>
        </Box>
      </Stack>

      <Stack p="10px 20px">
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <FormControl w={["100%", "100%", "48%"]} my={2}>
              <FormInputs
                type="text"
                label="Name"
                name="fullName"
                value={formData?.fullName}
                handleChange={handleChange}
              />
            </FormControl>

            <FormControl w={["100%", "100%", "48%"]} my={2}>
              <FormInputs
                type="email"
                label="Email"
                name="email"
                value={formData?.email}
                handleChange={handleChange}
              />
            </FormControl>
          </Box>

          <Flex justifyContent="center" mt={4}>
            <CustomBtn
              bg="btnbg"
              text="Update"
              childComp={<MdDriveFolderUpload size={20} color="white" />}
              type="submit"
              loading={isLoading}
              disabled={
                formData.email === state?.userInfo?.email &&
                formData.fullName === state?.userInfo?.fullName
              }
            />
          </Flex>
        </form>
      </Stack>
    </Box>
  );
}

export default AccountDetails;
