import { Box, FormLabel } from "@chakra-ui/react";
import { FormInputs } from "./FormInput";
import React, { useState } from "react";
import { CustomBtn } from "./CustomBtn";
import CreatableSelect from "react-select/creatable";
import { addSubscription } from "../services/subscription";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { successNotifier } from "..";

function SubscriptionModal({ onClose }) {
  const [formValue, setFormValue] = useState({
    subscriptionName: "",
    subscriptionAmount: "",
    subscriptionBenefits: [],
  });
  const queryClient = useQueryClient();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValue((prev) => ({ ...prev, [name]: value }));
  };

  const handleBenefitsChange = (selectedOptions) => {
    const benefits = selectedOptions.map((option) => option.value);
    setFormValue((prev) => ({ ...prev, subscriptionBenefits: benefits }));
  };

  const handleCreateOption = (inputValue) => {
    const newOption = { value: inputValue, label: inputValue };
    setFormValue((prev) => ({
      ...prev,
      subscriptionBenefits: [...prev.subscriptionBenefits, newOption.value],
    }));
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: addSubscription,
    onSuccess: () => {
      successNotifier("Subscription Created Successfully");
      queryClient.invalidateQueries({ queryKey: ["subscriptions"] });
      onClose();
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = { ...formValue };
    mutate(payload);
  };

  const options = formValue.subscriptionBenefits.map((benefit) => ({
    value: benefit,
    label: benefit,
  }));

  return (
    <Box>
      <FormInputs
        label="Subscription Name"
        type="text"
        name="subscriptionName"
        value={formValue.subscriptionName}
        handleChange={handleChange}
      />
      <FormInputs
        label="Amount"
        type="text"
        name="subscriptionAmount"
        value={formValue.subscriptionAmount}
        handleChange={handleChange}
      />
      <Box>
        <FormLabel width={"100%"} color="#000000">
          Subscription Benefits
        </FormLabel>
        <CreatableSelect
          id="subscriptionBenefits"
          isMulti
          options={options}
          value={options}
          onChange={handleBenefitsChange}
          onCreateOption={handleCreateOption}
          allowCreateWhileLoading={false}
          allowCreate={true}
          formatCreateLabel={(inputValue) => `"${inputValue}"`}
        />
      </Box>

      <Box align="center" my={4}>
        <CustomBtn
          text={"Add Subscription"}
          handleClick={handleSubmit}
          loading={isLoading}
          disabled={
            !formValue.subscriptionName ||
            !formValue.subscriptionAmount ||
            formValue.subscriptionBenefits.length === 0
          }
        />
      </Box>
    </Box>
  );
}

export default SubscriptionModal;
