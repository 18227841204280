import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";

const PayinPayoutChart = ({
  selectedOption,
  monthlyPayout,
  monthlyPayin,
  dailyPayin,
  dailyPayout,
}) => {
  const data = {
    labels:
      selectedOption === "daily"
        ? Object.keys(dailyPayin ? dailyPayin : {})
        : Object.keys(monthlyPayout ? monthlyPayout : {}),

    datasets: [
      {
        label: "Pay In",
        data:
          selectedOption === "daily"
            ? Object.values(dailyPayin ? dailyPayin : {})
            : Object.values(monthlyPayin ? monthlyPayin : {}),
        backgroundColor: "#6C87A3",
        borderWidth: 1,
      },
      {
        label: "Pay Out",
        data:
          selectedOption === "daily"
            ? Object.values(dailyPayout ? dailyPayout : {})
            : Object.values(monthlyPayout ? monthlyPayout : {}),
        backgroundColor: "#3C7B79",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: false,
    },
    scales: {
      y: {
        ticks: {
          stepSize: 10,
          callback: (value) => `${value}k`,
        },
      },
    },
    responsive: true,
    interaction: {
      intersect: false,
    },
  };

  return <Bar data={data} options={options} />;
};
export default PayinPayoutChart;

