import React from "react";
import { Box, Flex, Image, Text } from "@chakra-ui/react";

import { useNavigate } from "react-router-dom";
import { BiMenu } from "react-icons/bi";
import { useGetState } from "../contexts/useGetState";
import NotificationDrawer from "./notifications/Drawer";

const NavBar = ({ setShowSidebar, setTabWidth }) => {
  const navigate = useNavigate();

  const { state } = useGetState();

  return (
    <Flex
      width={"100%"}
      bg="#FBFBFB"
      p={["20px", "20px", "20px 40px"]}
      color={"#1A0031"}
      justifyContent={["space-between"]}
      alignItems="center"
      textAlign={"center"}
    >
      <Box width={["20%", "20%", "400px"]}>
        {window.innerWidth > 1100 ? (
          // <SearchField />
          <Text
            fontSize={["12px", "12px", "24px"]}
            fontWeight="semibold"
            lineHeight="1"
          >
            Welcome {state?.userInfo?.fullName}
          </Text>
        ) : (
          <BiMenu
            size={24}
            cursor={"pointer"}
            onClick={() => {
              setShowSidebar(true);
              setTabWidth("240px");
            }}
          />
        )}
      </Box>
      <Box className="logo" textAlign={"left"}>
        <Flex justify={"center"} align="center">
          <NotificationDrawer />

          <Box
            mr={["10px"]}
            cursor="pointer"
            onClick={() => navigate("/account-settings")}
          >
            <Image
              src={state?.userInfo?.image}
              alt={"User"}
              width={["30px", "30px", "40px"]}
              height={["30px", "30px", "40px"]}
            />
          </Box>
          <Box>
            <Text
              fontSize={["12px", "12px", "16px"]}
              fontWeight="semibold"
              lineHeight="1"
              textTransform="capitalize"
            >
              {state?.userInfo?.fullName}
            </Text>
            <Text fontSize={"11px"} color="#686868">
              {state?.userInfo?.email}
            </Text>
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};

export default NavBar;
