import { Box, Heading } from '@chakra-ui/react'
import React from 'react'
import AccountDetails from '../components/account/AccountSettings'
import { useGetState } from '../contexts/useGetState'
import FullPageLoader from '../components/FullPageLoader'

function AccountSettingPage() {
 const { state } = useGetState();

    return state?.loading ? (
      <FullPageLoader />
    ) : (
      <Box width={["100%", "100%", "80vw", "75vw"]}>
        <Heading as="h2" fontSize={["20px", "22px", "30px"]} mb="30px">
          Account Settings
        </Heading>

        <AccountDetails state={state} />
      </Box>
    );
}

export default AccountSettingPage
