import { Box, Flex, Image, Stack, Text } from "@chakra-ui/react";
import { Link, NavLink } from "react-router-dom";
import { BiLogOutCircle } from "react-icons/bi";

import logo from "../assets/loginLogo.png";
import { navList } from "./routes";
import { AiOutlineClose } from "react-icons/ai";

const SideBar = ({ setShowSidebar, showSidebar, setTabWidth, tabWidth }) => {
  const handleLogOut = () => {
    sessionStorage.removeItem("#EXPTR#SU#PADMIN");
    window.location.reload();
  };

  return (
    <Stack
      spacing={"8px"}
      // width={["70%", "70%", "70%", "70%", "100%"]}
      h="100vh"
      alignItems={"flex-start"}
      bg="primary"
      color="#fff"
      pr="20px"
      // display={showSidebar ? "block" : "none"}
    >
      <Flex
        // mt="20px"
        my="40px"
        justify={["space-between", "space-between", "center"]}
        align="center"
        w="100%"
        // onClick={() => window.location.href("/")}
      >
        <Link to="/">
          <Image
            src={logo}
            alt="Filtrd tracker logo"
            ml={["20px", "", "-30px"]}
            width={["80px", "80px", "120px"]}
          />
        </Link>
        {window.innerWidth < 900 ? (
          <AiOutlineClose
            cursor={"pointer"}
            size={24}
            onClick={() => {
              setShowSidebar(!showSidebar);
              // setTabWidth("80px");
            }}
          />
        ) : window.innerWidth > 900 && window.innerWidth <= 1100 ? (
          <AiOutlineClose
            cursor={"pointer"}
            size={24}
            onClick={() => {
              setShowSidebar(true);
              setTabWidth("80px");
            }}
          />
        ) : null}
      </Flex>

      <style>{`
      
      .notActive .icon-class{
        color: #fff;
        
      }
      
      `}</style>

      {navList.map(({ text, icon: Icon, link }, id) => {
        return (
          <NavLink
            key={id}
            to={link}
            className={({ isActive }) =>
              isActive ? "activeClass" : "notActive"
            }
            style={({ isActive }) =>
              isActive
                ? {
                    borderTopRightRadius: "25px",
                    borderBottomRightRadius: "25px",
                    borderLeft: "4px solid white",
                    background: "#3B6464",
                    color: "white",
                    padding: "7px 36px",
                    // window.innerWidth >= "1100px" ? "10px 36px" : "7px 10px",
                    width: window.innerWidth >= "1100px" ? "240px" : "100%",
                    textAlign: "center",
                    fontWeight: "bold",
                  }
                : {
                    color: "#fff",
                    padding: "6px 40px",
                    // window.innerWidth >= "1100px" ? "10px 40px" : "7px 10px",
                    fontWeight: "bold",
                    textAlign: "center",
                  }
            }
          >
            <Stack
              key={id}
              direction={"row"}
              spacing="10px"
              alignItems={"center"}
              // justify="center"
              // width="240px"
              // marginLeft="20px"
            >
              <Box>
                <Icon
                  className="icon-class"
                  color="white"
                  fontSize={"1.2rem"}
                />
              </Box>

              <Text
                display={
                  tabWidth === "80px" &&
                  window.innerWidth < 1100 &&
                  window.innerWidth > 600
                    ? "none"
                    : "block"
                }
                fontWeight={"normal"}
                fontSize={[
                  "0.85rem",
                  "0.85rem",
                  "0.85rem",
                  "0.85rem",
                  "0.96rem",
                ]}
              >
                {text}
              </Text>
            </Stack>
          </NavLink>
        );
      })}

      <Box
        p="30px 40px"
        // width="100%"
        position={"absolute"}
        bottom="0"
        color="#fff"
        left="0"
      >
        <Stack
          direction={"row"}
          align="center"
          cursor={"pointer"}
          color="#fff"
          onClick={handleLogOut}
        >
          <BiLogOutCircle size={24} />
          <Text
            display={
              tabWidth === "80px" &&
              window.innerWidth < 1100 &&
              window.innerWidth > 600
                ? "none"
                : "block"
            }
          >
            Log out
          </Text>
        </Stack>
      </Box>
    </Stack>
  );
};

export default SideBar;
