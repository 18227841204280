import React from "react";
import { Th, Thead, Tooltip, Tr } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";

const TableHead = ({ tableHeadData }) => {
  const location = useLocation();

  return (
    <Thead width="100% !important">
      <Tr width="100% !important">
        {tableHeadData?.map((thead, index) => (
          <Tooltip label={thead} key={index}>
            <Th
              // isTruncated
              width={location.pathname === "/categories" ? "100%" : ""}
              maxW="170px !important"
              textTransform={"capitalize !important"}
              fontSize="15px"
              // fontWeight={"semibold"}
              color="#505050"
              py={
                location.pathname === "/"
                  ? "10px"
                  : location.pathname === "/payments-schedule"
                  ? "2"
                  : "20px"
              }
              borderRight={
                location.pathname === "/categories"
                  ? "none"
                  : "1px solid #dadedf !important"
              }
            >
              {thead}
            </Th>
          </Tooltip>
        ))}
      </Tr>
    </Thead>
  );
};

export default TableHead;
