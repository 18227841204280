import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { CustomBtn } from "./CustomBtn";

function DeleteCompanyModal({
  texts,
  text,
  onClose,
  content,
  handleUpdate,
  loading,
}) {
  return (
    <Box>
      <Text textAlign="center">{content}</Text>
      <Flex align="center" mt="30px" gap="10px" justifyContent="center">
        <CustomBtn
          text={texts || "Delete"}
          handleClick={handleUpdate}
          loading={loading}
        />
        <CustomBtn
          bg="#890e0a"
          border="none"
          text={text || "cancel"}
          handleClick={onClose}
        />
      </Flex>
    </Box>
  );
}

export default DeleteCompanyModal;
