import { errorNotifier, successNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";

export const getAllCompanies = async () => {
  try {
    const data = await http.get(AUTH_ROUTES.GET_ALL_COMPAIES);
    return data?.data?.data;
  } catch (e) {
    if (e?.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  }
};

export const createCompanies = async (payload) => {
  try {
    await http.post(AUTH_ROUTES.CREATE_COMPANY, payload);
    successNotifier("Company Created Successfully");
  } catch (e) {
    if (e.response) {
      if (e?.response?.data?.message === "Unable to verify token.") {
        errorNotifier("Session timed out");
        window.location.reload();
      } else {
        errorNotifier(
          e.response?.data?.message || e.response?.data?.errors?.[0]?.msg
        );
      }
    } else {
      errorNotifier("Please check your network connection");
    }
  }
};

export const updateCompanyDetails = async (payload) => {
  try {
    await http.put(AUTH_ROUTES.UPDATE_COMPANY_DETAILS(payload?._id), payload);
    successNotifier("Company Updated Successfully");
  } catch (e) {
    if (e.response) {
      if (e?.response?.data?.message === "Unable to verify token.") {
        errorNotifier("Session timed out");
        window.location.reload();
      } else {
        errorNotifier(
          e.response?.data?.message || e.response?.data?.data?.message
        );
      }
    } else {
      errorNotifier("Please check your network connection");
    }
  }
};

export const searchCompany = async (
  setCompany,
  keyword,
  setLoading,
  setNotFoundError
) => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.SEARCH_COMPANY(keyword));
    setCompany(data);
  } catch (e) {
    setNotFoundError((prev) => !prev);
  } finally {
    setLoading(false);
  }
};

export const suspendCompany = async (payload) => {
  try {
    await http.put(AUTH_ROUTES.SUSPEND_COMPANY(payload?._id), payload);
    const message = payload?.isSuspended ? "Suspended" : "Unsuspended";
    successNotifier(`Company ${message}`);
  } catch (e) {
    if (e.response) {
      errorNotifier(
        e.response?.data?.message || e.response?.data?.data?.message
      );
    } else {
      errorNotifier("Network error, please check your internet connection");
    }
  }
};
export const toggleCompany = async (payload) => {
  try {
    await http.put(AUTH_ROUTES.TOGGLE_COMPANY(payload?._id), payload);

    if (payload?.walletEnabled === true) {
      successNotifier("Company Enabled");
    } else {
      successNotifier("Company Disabled");
    }
  } catch (e) {
    if (e.response) {
      errorNotifier(
        e.response?.data?.message || e.response?.data?.data?.message
      );
    } else {
      errorNotifier("Network error, please check your internet connection");
    }
  }
};
