import { errorNotifier } from "../index";
import http, { AUTH_ROUTES } from "./api";

export const getNotifications = async () => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_NOTIFICATIONS);
    return data;
  } catch (e) {
    if (e?.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  }
};
export const readNotification = async (id) => {
  try {
    const result = await http.put(AUTH_ROUTES.READ_NOTIFICATION(id));
    // setLoading(false);
    // setRefresh((prev) => !prev);
  } catch (e) {
    // setLoading(false);
  }
};
