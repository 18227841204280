import { Stack, Box, Flex } from "@chakra-ui/react";
import { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import NavBar from "./NavBar";
import Sidebar from "./Sidebar";
import Companies from "../pages/Companies";
import Overview from "../pages/Overview";
import SubscriptionPage from "../pages/SubscriptionPage";
import TransactionPage from "../pages/TransactionPage";
import AnalyticsPage from "../pages/AnalyticsPage";
import AccountSettingPage from "../pages/AccountSettingPage";
import Support from "../pages/Support";

function AuthenticatedRoutes() {
  const [showSidebar, setShowSidebar] = useState(() =>
    window.innerWidth < 720 ? false : true
  );
  const [tabWidth, setTabWidth] = useState("80px");
  //  const userId = state?.userInfo?._id;

  //  useEffect(() => {
  //    connect({ userId, type: "Admin" }).then(() => {
  //      setIsLoading(false);
  //    });

  //    return disconnect;
  //  }, [userId]);

  useEffect(() => {
    const handleResize = () =>
      window.innerWidth <= 720 ? setShowSidebar(false) : setShowSidebar(true);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [tabWidth]);

  return (
    <Suspense fallback={<p></p>}>
      <BrowserRouter>
        <Stack direction={"row"} position="relative" width="100%">
          {showSidebar && (
            <Box
              width={["", "", "", tabWidth, "260px"]}
              position={"fixed"}
              left={"0"}
              zIndex="1032"
            >
              <Sidebar
                showSidebar={showSidebar}
                setShowSidebar={setShowSidebar}
                setTabWidth={setTabWidth}
                tabWidth={tabWidth}
              />
            </Box>
          )}

          <Flex
            width="100%"
            position={"relative"}
            // overflow="hidden"
            top="0"
            // left={"-8px"}
          >
            <Stack
              flex="1"
              alignItems={"center"}
              borderRadius="2px"
              position={"fixed"}
              right={[0, 0, 0, "0px", "0"]}
              left={[0, 0, 0, "80px", "180px"]}
              zIndex="1022"
            >
              <NavBar
                showSidebar={showSidebar}
                setTabWidth={setTabWidth}
                setShowSidebar={setShowSidebar}
              />
            </Stack>
            <Box
              width={["100%", "80%", "80%", "88%", "80%"]}
              position={"relative"}
              top="80px"
              left={[0, 0, 0, "80px", "260px"]}
              color="#000"
              p={["30px", "30px", "30px", "30px 10px", "30px"]}
              overflowX={"hidden !important"}
            >
              <Routes>
                <Route path="/" element={<Overview />} />
                <Route path="/companies" element={<Companies />} />
                <Route path="/subscriptions" element={<SubscriptionPage />} />
                <Route path="/transactions" element={<TransactionPage />} />
                <Route path="/analytics" element={<AnalyticsPage />} />
                <Route
                  path="/account-settings"
                  element={<AccountSettingPage />}
                />
                <Route path="/support" element={<Support />} />
              </Routes>
            </Box>
          </Flex>
        </Stack>
      </BrowserRouter>
    </Suspense>
  );
}

export default AuthenticatedRoutes;
