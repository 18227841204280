import { errorNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";

export const getMonthlyCategoryExpense = async () => {
  try {
    const data = await http.get(AUTH_ROUTES.GET_MONTHLY_CATEGORY_EXPENSE);
    return data?.data?.data;
  } catch (e) {
    if (e?.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  }
};

export const getDailyCategoryExpense = async () => {
  try {
    const data = await http.get(AUTH_ROUTES.GET_DAILY_CATEGORY_EXPENSE);
    return data?.data?.data;
  } catch (e) {
    if (e?.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  }
};
export const getExpensePerItem = async () => {
  try {
    const data = await http.get(AUTH_ROUTES.GET_EXPENSE_PER_ITEM);
    return data?.data?.data;
  } catch (e) {
    if (e?.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  }
};

export const getDailyPayin = async () => {
  try {
    const data = await http.get(AUTH_ROUTES.GET_DAILY_PAYIN);
    return data?.data?.data?.totalFundAccrued;
  } catch (e) {
    if (e?.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  }
};

export const getMontlyPayin = async () => {
  try {
    const data = await http.get(AUTH_ROUTES.GET_MONTHLY_PAYIN);
    return data?.data?.data?.totalFundAccrued;
  } catch (e) {
    if (e?.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  }
};

export const getDailyPayout = async () => {
  try {
    const data = await http.get(AUTH_ROUTES.GET_DAILY_PAYOUT);
    return data?.data?.data?.totalFundDisbursed;
  } catch (e) {
    if (e?.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  }
};

export const getMonthlyPayout = async () => {
  try {
    const data = await http.get(AUTH_ROUTES.GET_MONTHLY_PAYOUT);
    return data?.data?.data?.totalFundsDisbursed;
  } catch (e) {
    if (e?.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  }
};

export const getTopSpender = async () => {
  try {
    const data = await http.get(AUTH_ROUTES.GET_TOP_SPENDERS);
    return data?.data?.data;
  } catch (e) {
    if (e?.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  }
};

export const getMonthlySpenderList = async () => {
  try {
    const data = await http.get(AUTH_ROUTES.GET_MONTHLY_SPENDERS_LIST);
    return data?.data?.data;
  } catch (e) {
    if (e?.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  }
};
export const getMonthlyPayoutToVendors = async () => {
  try {
    const data = await http.get(AUTH_ROUTES.GET_MONTHLY_PAYOUTSTOVENDORS);
    return data?.data?.data;
  } catch (e) {
    if (e?.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  }
};
export const getAnnualPayoutToVendors = async () => {
  try {
    const data = await http.get(AUTH_ROUTES.GET_ANNUAL_PAYOUTSTOVENDORS);
    return data?.data?.data;
  } catch (e) {
    if (e?.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  }
};
