import { errorNotifier, successNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";

export const sendBroadcast = async (payload) => {
  try {
    await http.post(AUTH_ROUTES.SEND_BROADCAST, payload);
    successNotifier("Broadcast Sent Successfully");
 
  } catch (e) {
    if (e.response) {
        if (e?.response?.data?.message === "Unable to verify token.") {
          errorNotifier("Session timed out");
          window.location.reload();
        } else {
          errorNotifier(
            e.response?.data?.message || e.response?.data?.data?.message
          );
        }
      } else {
        errorNotifier("Please check your network connection");
      }
    }
};