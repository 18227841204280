import { TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import React from "react";
import { transactionsPayinTableHead, transactionsPayoutTableHead } from "../constants/table.constant";
import TransactionTable from "../table/transaction/TransactionTable";
import TabHeadings from "./TabHeading";

const CustomTab = ({
  tabHeadings = ["Pay Out", "Pay In"],
  tableHeadData,
  transactions,
  setTabName,
  payin,
}) => {
  return (
    <Tabs width="100%" >
      <TabHeadings tabHeadings={tabHeadings} setTabName={setTabName}  />

      <TabPanels width="100% !important" mt="20px">
        <TabPanel key="payOut" width="100% !important" p="0 !important">
          <TransactionTable
            payin={false}
            transactions={transactions}
            tableHeadData={transactionsPayoutTableHead}
          />
        </TabPanel>
        <TabPanel key="payIn" width="100% !important" p="0 !important">
          <TransactionTable
            transactions={transactions}
            tableHeadData={transactionsPayinTableHead}
            payin={true}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
};

export default CustomTab;
