import React from "react";
import { Box, Table } from "@chakra-ui/react";
import TableHead from "../TableHead";
import TransactionTableBody from "./TransactionTableBody";

const TransactionTable = ({ transactions, tableHeadData, payin }) => {
  return (
    <Box width="100%" overflowX={["scroll", "scroll", "hidden"]}>
      <Table
        variant="striped"
        colorScheme="gray"
        bg="#fff"
        borderRadius={"5px"}
      >
        <TableHead tableHeadData={tableHeadData} />
        <TransactionTableBody
          data={transactions}
          payin={payin}
        />
      </Table>
    </Box>
    // </TableContainer>
  );
};

export default TransactionTable;
