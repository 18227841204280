import React, { useEffect, useState } from "react";
import BoxShadowContainer from "./BoxShadowContainer";
import { useQuery } from "@tanstack/react-query";
import { getMonthlySpenderList, getTopSpender } from "../../services/analytics";
import {
  Avatar,
  AvatarBadge,
  Box,
  Flex,
  Heading,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useMemo } from "react";

export const TopCompanyCard = () => {
  const [selectedMonth, setSelectedMonth] = useState(null);

  const handleSelectChange = (event) => {
    const selectedMonth = event.target.value;
    setSelectedMonth(selectedMonth);
  };

  const { data: topSpender, isLoading } = useQuery({
    queryKey: ["topSpender"],
    queryFn: getTopSpender,
  });

  const { data: monthlySpenderList, isLoading: loading } = useQuery({
    queryKey: ["monthlySpenderList"],
    queryFn: getMonthlySpenderList,
  });

  // const monthEntries = monthlySpenderList
  //   ? Object.entries(monthlySpenderList)
  //   : [];

  const monthEntries = useMemo(
    () => (monthlySpenderList ? Object.entries(monthlySpenderList) : []),
    [monthlySpenderList]
  );

  useEffect(() => {
    if (monthEntries.length > 0) {
      setSelectedMonth(monthEntries[0][0]);
    }
  }, [monthEntries]);

  return loading || isLoading ? (
    <Text>...Loading</Text>
  ) : (
    <BoxShadowContainer w={["100%", "100%", "100%"]} color={"#393838"}>
      <Flex justify={"space-between"} w={"100%"} align={"center"}>
        <Box>
          <Heading fontSize={"25px"}>Top Company Spender</Heading>
        </Box>
        <Select
          placeholder="Select Month"
          color={"#3C7B79"}
          borderColor={"#3C7B79"}
          w={"200px"}
          value={selectedMonth}
          onChange={handleSelectChange}
        >
          {monthEntries.map(([month], i) => (
            <option key={i} value={month}>
              {month}
            </option>
          ))}
        </Select>
      </Flex>
      <Flex
        pt={["0px", "0px", "0px", "50px"]}
        justify={"space-between"}
        direction={["column", "column", "column", "row"]}
      >
        <Box w={["100%", "100%", "100%", "60%"]}>
          <Flex w={"100%"} direction={["column", "column", "column", "row"]}>
            <Stack
              spacing={4}
              textAlign={"center"}
              justify={"center"}
              align={"center"}
              mt={"70px"}
            >
              <Avatar
                size="xl"
                name={topSpender?.[1]?.companyName}
                alt={`Avatar of ${topSpender?.[1]?.companyName}`}
                src={topSpender?.[1]?.image}
                border={"4px solid #FFFFFF"}
                shadow={"lg"}
                mb={"10px"}
              >
                <AvatarBadge bg="#FFF4EB" boxSize="1.10em" border={"none"}>
                  <Text
                    color={"#000"}
                    fontSize={"15px"}
                    fontWeight={"semibold"}
                    textTransform={"capitalize"}
                  >
                    2nd
                  </Text>
                </AvatarBadge>
              </Avatar>
              <Heading fontSize={"23px"}>Top Company Spender</Heading>
              <Text color={"#006C6C"} fontSize={"20px"} fontWeight={"semibold"}>
                {topSpender?.[1]?.companyName}
              </Text>
            </Stack>
            <Stack
              spacing={4}
              textAlign={"center"}
              justify={"center"}
              align={"center"}
              mt={["70px", "70px", "70px", "0px"]}
            >
              <Avatar
                w="170px !important"
                height={"170px"}
                name={topSpender?.[0]?.companyName}
                alt={`Avatar of ${topSpender?.[0]?.companyName}`}
                src={topSpender?.[0]?.image}
                border={"4px solid #FFFFFF"}
                shadow={"lg"}
                mb={"10px"}
              >
                <AvatarBadge bg="#FFF4EB" boxSize="2.25em" border={"none"}>
                  <Text
                    color={"#000"}
                    fontSize={"15px"}
                    fontWeight={"semibold"}
                    textTransform={"capitalize"}
                  >
                    1st
                  </Text>
                </AvatarBadge>
              </Avatar>{" "}
              <Heading fontSize={"23px"}>Top Company Spender</Heading>
              <Text color={"#006C6C"} fontSize={"20px"} fontWeight={"semibold"}>
                {topSpender?.[0]?.companyName}
              </Text>
            </Stack>
            <Stack
              spacing={4}
              textAlign={"center"}
              justify={"center"}
              align={"center"}
              mt={"70px"}
            >
              <Avatar
                size="xl"
                name={topSpender?.[2]?.companyName}
                alt={`Avatar of ${topSpender?.[2]?.companyName}`}
                src={topSpender?.[2]?.image}
                border={"4px solid #FFFFFF"}
                shadow={"lg"}
                mb={"10px"}
              >
                <AvatarBadge bg="#FFF4EB" boxSize="1.10em" border={"none"}>
                  <Text
                    color={"#000"}
                    fontSize={"15px"}
                    fontWeight={"semibold"}
                    textTransform={"capitalize"}
                  >
                    3rd
                  </Text>
                </AvatarBadge>
              </Avatar>
              <Heading fontSize={"23px"}>Top Company Spender</Heading>
              <Text color={"#006C6C"} fontSize={"20px"} fontWeight={"semibold"}>
                {topSpender?.[2]?.companyName}
              </Text>
            </Stack>
          </Flex>
        </Box>
        <Box
          w={["100%", "100%", "100%", "37%"]}
          h={"350px"}
          overflowY={"scroll"}
          mt={["50px", "50px", "50px", "0px"]}
        >
          {!monthlySpenderList || monthlySpenderList < 0 ? (
            <Flex
              justify={"center"}
              align={"center"}
              bg={"#F2F2F2"}
              h={"350px"}
            >
              <Text fontSize={"20px"} fontWeight={"semibold"}>
                No more top spenders
              </Text>
            </Flex>
          ) : (
            monthlySpenderList[selectedMonth]?.map((data, index) => {
              const serialNumber = index + 4;
              return (
                <Flex
                  bg={"#F2F2F2"}
                  justify={"space-between"}
                  align={"center"}
                  p={"10px 20px"}
                  rounded={"sm"}
                  mb={"30px"}
                  key={"index"}
                >
                  <>
                    <Text>{serialNumber}</Text>
                    <Avatar
                      size={"sm"}
                      name={data?.companyName}
                      src={data?.companyName}
                      alt={`Avatar of ${data?.companyName}`}
                    />
                    <Text w={"40%"} fontSize={"17px"} fontWeight={"semibold"}>
                      {data?.companyName || "--"}
                    </Text>
                    <Text w={"25%"} color={"#006C6C"}>
                      {data?.totalAmount || "--"}
                    </Text>
                  </>
                </Flex>
              );
            })
          )}
        </Box>
      </Flex>
    </BoxShadowContainer>
  );
};
