import React from "react";
import {
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const formColor = "#616161";
const fontSize = "18px";

export const FormInputs = ({
  label,
  type,
  name,
  value,
  handleChange,
  height,
  disabled,
  backgroundColor,
  placeholder,
}) => {
  return (
    <FormControl mb="20px">
      <FormLabel color={formColor} fontSize={fontSize}>
        {label}
      </FormLabel>
      <Input
        type={type}
        h="48px"
        name={name}
        value={value}
        onChange={handleChange}
        height={height}
        isDisabled={disabled}
        placeholder={placeholder}
        backgroundColor={backgroundColor}
      />
    </FormControl>
  );
};

export const CustomInputGroup = ({
  label,
  type,
  name,
  value,
  handleChange,
  height,
  backgroundColor,
  placeholder,
}) => {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  
  return (

    <FormControl width="100%" mb="10px">
      <FormLabel width={"100%"} color="#000000">
        {label}
      </FormLabel>
      <InputGroup size="md">
        <Input
          type={show ? "text" : type}
          h="48px"
          name={name}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          backgroundColor={backgroundColor}
        />
        <InputRightElement width="4.5rem">
          {show ? (
            <AiOutlineEye onClick={handleClick} />
          ) : (
            <AiOutlineEyeInvisible onClick={handleClick} />
          )}
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
};
