import { errorNotifier, successNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";

export const addSubscription = async (payload) => {
  try {
    await http.post(AUTH_ROUTES.CREATE_SUBSCRIPTION, payload);
  } catch (e) {
    if (e.response) {
      if (e?.response?.data?.message === "Unable to verify token.") {
        errorNotifier("Session timed out");
        window.location.reload();
      } else {
        errorNotifier(
          e.response?.data?.message || e.response?.data?.data?.message
        );
      }
    } else {
      errorNotifier("Please check your network connection");
    }
  }
};

export const getSubscription = async () => {
  try {
    const {
      data: { data },
    } = await http.get(AUTH_ROUTES.GET_SUBSCRIPTION);
    return data;
  } catch (e) {
    if (e?.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  }
};

export const deleteSubscription = async (id) => {
  try {
    await http.delete(AUTH_ROUTES.DELETE_SUBSCRIPTION(id));
    successNotifier("Subscription Deleted Successfully");
  } catch (e) {
    if (e.response) {
      errorNotifier(
        e.response?.data?.message || e.response?.data?.data?.message
      );
    } else {
      errorNotifier("Please check your network connection");
    }
  }
};

export const updateSubscription = async (payload) => {
  try {
    await http.put(AUTH_ROUTES.UPDATE_SUBSCRIPTION(payload?._id), payload);
  } catch (e) {
    if (e.response) {
      errorNotifier(
        e.response?.data?.message || e.response?.data?.data?.message
      );
    } else {
      errorNotifier("Please check your network connection");
    }
  }
};
