import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { CustomBtn } from "./CustomBtn";

function DeleteSubscription({ text, handleDelete, loading }) {
  return (
    <Box>
      <Text>Are You Sure You Want to Delete This Subscription ?</Text>
      <Flex align="center" mt="30px" gap="10px" justifyContent="center">
        <CustomBtn
          text={text || "Delete"}
          handleClick={handleDelete}
          loading={loading}
        />
        <CustomBtn bg="#890e0a" border="none" text={text || "cancel"} />
      </Flex>
    </Box>
  );
}

export default DeleteSubscription;
