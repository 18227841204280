import React from "react";
import {
  Circle,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { IoMdNotificationsOutline } from "react-icons/io";
import NotificationContent from "./NotificationContent";

const NotificationDrawer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = React.useRef();

  return (
    <>
      <Flex cursor="pointer" onClick={onOpen} align="center">
        <Circle
          width={["34px", "34px", "40px"]}
          height={["34px", "34px", "40px"]}
          bg="btnbg"
          color="white"
          mr={["10px", "10px", "30px"]}
          cursor="pointer"
        >
          <IoMdNotificationsOutline size={22} />
        </Circle>
      </Flex>

      <Drawer
        size={"md"}
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <Flex
            width="100%"
            align={"center"}
            justify={"space-between"}
            boxShadow="md"
            p="20px 30px !important"
          >
            <Text
              fontSize={"18px"}
              color={"#154141"} 
              fontWeight="semibold"
              textTransform={"capitalize"}
            >
              Notifications
            </Text>
          </Flex>

          <DrawerBody p="0px !important">
            <NotificationContent />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default NotificationDrawer;
