import { Box, Flex, Image, Text } from "@chakra-ui/react";
import expense from "../assets/expense.png";
import user from "../assets/user.png";
import React from "react";
import { Link } from "react-router-dom";
import OverviewTable from "../components/overview/OverviewTable";
import { useNavigate } from "react-router-dom";
import {
  getAnalyticsOverview,
  getUserRequests,
} from "../services/overview";
import { useQuery } from "@tanstack/react-query";
import { AverageRevenueCard } from "../components/analytics/AverageRevenueCard";

const OverviewPage = () => {
  const navigate = useNavigate();

  const { data: overview } = useQuery({
    queryKey: ["analyticsOverview"],
    queryFn: getAnalyticsOverview,
  });

  const { data: requests, isLoading: requestLoading } = useQuery({
    queryKey: ["userRequests"],
    queryFn: getUserRequests,
  });

  return (
    <Flex
      width="100%"
      justify={"space-between"}
      direction={["column-reverse", "column-reverse", "column", "row"]}
    >
      {/*Left side*/}
      <Box width={["100%", "100%", "100%", "30%"]}>
        <Box
          bg={"#FFFFFF"}
          p={"15px 20px"}
          shadow={"md"}
          rounded={"md"}
          lineHeight={1.5}
          mb={"20px"}
        >
          <Text color={"#000000"} fontSize={"1.5rem"} fontWeight={"semibold"}>
            Overview
          </Text>
          <Text color={"#575555"} fontSize={"1rem"} fontWeight={400}>
            Welcome Back, Manage all Expense and Transactions
          </Text>
        </Box>
     
        {/**Expenses card*/}
        <Box
          bg={"#F4E8E8"}
          p={"15px 20px"}
          shadow={"md"}
          cursor="pointer"
          onClick={() => {
            navigate("/analytics");
          }}
          rounded={"md"}
          lineHeight={1.5}
          mb={"25px"}
        >
          <Flex justify={"space-between"}>
            <Text color={"#F80404"} fontSize={"1rem"}>
              Total Expense Today
            </Text>
            <Image src={expense} alt="wallet icon" />
          </Flex>
          <Text color={"#000000"} fontSize={"2rem"} fontWeight={"bold"}>
            {overview?.expensesToday || 0}
          </Text>
          <Text color={"#575555"} fontSize={"1rem"} fontWeight={"semibold"}>
            60%
          </Text>
        </Box>
        <Box
          bg={"white"}
          p={"15px 20px"}
          shadow={"md"}
          rounded={"md"}
          lineHeight={1.5}
          mb={"25px"}
        >
          <Flex justify={"space-between"}>
            <Text fontSize={"1rem"}>Total Users</Text>
            <Image src={user} alt="wallet icon" />
          </Flex>
          <Text color={"#000000"} fontSize={"1.3rem"} fontWeight={"bold"}>
            {overview?.totalUsers || 0}
          </Text>
        </Box>
        <Box
          bg={"#FCF4E0"}
          p={"15px 20px"}
          shadow={"md"}
          rounded={"md"}
          lineHeight={1.5}
          mb={"25px"}
        >
          <Flex justify={"space-between"}>
            <Text fontSize={"1rem"}>Total Companies</Text>
            <Image src={user} alt="wallet icon" />
          </Flex>
          <Text color={"#000000"} fontSize={"1.3rem"} fontWeight={"bold"}>
            {overview?.totalCompanies || 0}
          </Text>
        </Box>
      </Box>
      {/*Right Side*/}
      <Box width={["100%", "100%", "100%", "66%"]} pb="20px" mt={"-30px"}>
        <AverageRevenueCard />
        <Flex justify={"space-between"} py={"16px"} fontWeight={"semibold"}>
          <Text color={"#000000"} fontSize={"1.1rem"}>
            Recent Request
          </Text>
          <Link to="/transactions">
            <Text color={"#3C7B79"} fontSize={"1.1rem"}>
              View more
            </Text>
          </Link>
        </Flex>
        <Box width={["100%"]} shadow={"md"}>
          <OverviewTable overview={requests} isLoading={requestLoading} />
        </Box>
      </Box>
    </Flex>
  );
};

export default OverviewPage;
