import { Box } from "@chakra-ui/react";
import jwtDecode from "jwt-decode";
import { Suspense, useEffect, useState } from "react";
import "./App.css";
import AuthenticatedRoutes from "./components/AuthenticatedRoutes";
import UnAuthenticatedRoutes from "./components/UnAuthenticatedRoutes";
import { UserProfileContextProvider } from "./contexts/UserContext";
import FullPageLoader from "./components/FullPageLoader";

function App() {
  //TODO: Token Implementation
  // const token = "#EXPTR#ADMIN";
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [checkingStatus, setCheckingStatus] = useState(true);

  useEffect(() => {
    const token = sessionStorage.getItem("#EXPTR#SU#PADMIN");
    if (token) {
      const decoded = jwtDecode(token);
      const expiryDate = new Date(decoded.exp * 1000);
      if (new Date() > expiryDate) {
        sessionStorage.removeItem("#EXPTR#SU#PADMIN");
      } else {
        // dispatch(authSetUser({ ...decoded }));
        setIsLoggedIn(true);
      }
    }

    setCheckingStatus(false);
  }, []);

  if (checkingStatus) {
    return <FullPageLoader />;
  }

  if (isLoggedIn) {
    return (
      <UserProfileContextProvider>
        <AuthenticatedRoutes />
      </UserProfileContextProvider>
    );
  }

  return (
    <Box className="App">
      <Suspense fallback={<p></p>}>
        <UnAuthenticatedRoutes />
      </Suspense>
    </Box>
  );
}

export default App;
