import {
  Box,
  Grid,
  Heading,
  Text,
  List,
  ListItem,
  Flex,
} from "@chakra-ui/react";
import React from "react";
import { CustomBtn } from "../components/CustomBtn";
import { formatToNaira } from "../utils/numberFormat";
import CustomModal from "../components/CustomModal";
import SubscriptionModal from "../components/SubscriptionModal";
import { AiOutlinePlus } from "react-icons/ai";
import { getSubscription, deleteSubscription } from "../services/subscription";
import { BsTrash } from "react-icons/bs";
import DeleteSubscription from "../components/DeleteSubscriptionModal";
import EditSubscription from "../components/EditSubscription";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import FullPageLoader from "../components/FullPageLoader";

function SubscriptionPage() {
  const queryClient = useQueryClient();

  const { data: subscription, isLoading } = useQuery({
    queryKey: ["subscriptions"],
    queryFn: getSubscription,
  });

  const { mutate: deleteMutation, isLoading: deletLoading } = useMutation({
    mutationFn: deleteSubscription,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["subscriptions"] });
    },
  });

  const handleDelete = (id) => {
    deleteMutation(id);
  };
  return isLoading ? (
    <FullPageLoader />
  ) : (
    <Box>
      <Flex
        justifyContent={"space-between"}
        my={"20px"}
        direction={["column", "row", "row"]}
        alignItems={"center"}
      >
        <Box>
          <Heading textAlign="center">Subscription Plan</Heading>
        </Box>
        <CustomModal
          header={`Create Subscription`}
          icon={
            <CustomBtn
              text="Create Subscription"
              bg={"#154141"}
              childComp={<AiOutlinePlus color="#fff" />}
            />
          }
        >
          <SubscriptionModal text="Add Company" />
        </CustomModal>
      </Flex>
      <Box
        my={["2rem"]}
        textAlign="center"
        maxW="600px"
        transform={["translate(0)", "translate(0)", "translate(40%)"]}
      >
        <Text fontSize={["14px", "14px", "18px"]}>
          {/* Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
          sed diam voluptua. At vero eos et accusam et */}
        </Text>
      </Box>
      <Grid
        justifyContent="space-around"
        gap={["20px", "20px", ""]}
        templateColumns={["repeat(1, 1fr)", "repeat(2, 1fr)", "repeat(3, 1fr)"]}
      >
        {subscription?.map((sub) => (
          <Box
            bg="#fff"
            p="30px"
            borderRadius="5px"
            key={sub?._id}
            h={"420px"}
            boxShadow="md"
          >
            <Box mb="20px">
              <Box
                textAlign="center"
                display={"flex"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Text color="#032A34" fontSize="25px" fontWeight="bold">
                  {sub?.subscriptionName}
                </Text>
                <CustomModal
                  icon={<BsTrash size="22px" color={"#154141"} />}
                  header="Delete Subscription"
                >
                  <DeleteSubscription
                    handleDelete={() => handleDelete(sub?._id)}
                    loading={deletLoading}
                  />
                </CustomModal>
              </Box>
              <Text
                bg={sub?.badge ? "#689A9A" : "none"}
                color="#fff"
                p="2px 5px"
                borderRadius="50px"
                fontSize={["12px", "12px", "15px"]}
              >
                {sub?.badge}
              </Text>
            </Box>
            <Box mb="20px" display={"flex"} alignItems={"center"}>
              <Text color="#032A34" fontSize="25px" fontWeight="bold" mr={"3"}>
                {formatToNaira(sub?.subscriptionAmount)}
              </Text>
              {/* <Text color="#689A9A" fontWeight="bold">
                /10 users
              </Text> */}
            </Box>
            <List styleType="disc" h={"150px"} pl={"2"}>
              {sub?.subscriptionBenefits.map((text, index) => (
                <ListItem
                  key={index}
                  color={"#707070"}
                  my={"2"}
                  fontSize={"14px"}
                >
                  {text}
                </ListItem>
              ))}
            </List>
            <Box mt="40px" display="flex" justifyContent={"center"}>
              <CustomModal
                header={`Edit Subscription`}
                icon={
                  <CustomBtn
                    text="Edit Plan"
                    bg={"#154141"}
                    borderRadius={"20px"}
                    width={"200px"}
                  />
                }
              >
                <EditSubscription data={sub}  />
              </CustomModal>
            </Box>
          </Box>
        ))}
      </Grid>
    </Box>
  );
}

export default SubscriptionPage;
