import { Box, Flex, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import { readNotification } from "../../services/notifications";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const NotificationCard = ({ data, setRefresh, onClose }) => {
  const queryClient = useQueryClient();
  const relativeTime = require("dayjs/plugin/relativeTime");
  dayjs.extend(relativeTime);

  const { mutate: markNotificationAsRead } = useMutation({
    mutationFn: (id) => readNotification(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["notifications"] });
      onClose();
    },
  });

  const handleRead = async () => {
    markNotificationAsRead(data?._id);
  };

  return (
    <Box
      p="10px"
      onClick={handleRead}
      bg={data?.read === true ? "white" : "#F4F4F4"}
      color={data?.read === true ? "#929292" : "black"}
      cursor="pointer"
    >
      <Flex alignItems={"center"} mb="5px">
        <Box ml="20px">
          <Text color="#352848" fontWeight="bold" fontSize=".9em">
            {data?.title}
          </Text>
          <Text fontSize=".87em">{data?.message}</Text>
          <Text fontSize={".7em"}>{dayjs(data?.createdAt).from(dayjs())}</Text>
        </Box>
      </Flex>
    </Box>
  );
};
