import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import React from "react";
import { AiOutlineSearch } from "react-icons/ai";

const SearchField = ({ placeholder = "", handleSearch, w }) => {
  return (
    <InputGroup width={"100%"}>
      <InputLeftElement
        pointerEvents="none"
        children={<AiOutlineSearch color="lightblue" size={24} />}
      />
      <Input
        bg="#ECECEE"
        color="#140236"
        borderRadius={"10px"}
        type="text"
        placeholder={`Search ${placeholder}`}
        onChange={handleSearch}
        autoComplete="off"
        w={w}
      />
    </InputGroup>
  );
};

export default SearchField;
