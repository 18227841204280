import { Tbody, Td, Text, Tooltip, Tr } from "@chakra-ui/react";
import dayjs from "dayjs";
import { AiOutlineEye } from "react-icons/ai";
import { formatToNaira } from "../../utils/numberFormat";
import CustomModal from "../CustomModal";
// import RequestModalContent from "../../RequestModalContent";

const OverviewTableBody = ({ overview }) => {
  const customTable = overview?.slice(0, 6);

  return (
    <Tbody>
      {customTable?.map((data, key) => (
        <Tr key={key} fontSize={"15px"}>
          <Tooltip label={data?.companies}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
            >
              {data?.companyId.fullName}
            </Td>
          </Tooltip>
          <Tooltip label={dayjs(data?.createdAt).format("DD MMM YYYY")}>
            <Td
              borderRight="1px solid #dadedf !important"
              isTruncated
              maxWidth="170px !important"
            >
              {dayjs(data?.createdAt).format("DD MMM YYYY")}
            </Td>
          </Tooltip>
          <Tooltip
            isTruncated
            maxWidth="170px !important"
            label={formatToNaira(data?.amount)}
          >
            <Td borderRight="1px solid #dadedf !important">
              {formatToNaira(data?.amount)}
            </Td>
          </Tooltip>
          <Tooltip isTruncated maxWidth="170px !important" label={data?.status}>
            <Td borderRight="1px solid #dadedf !important">
              <Text
                textAlign={"center"}
                bg={
                  data?.status?.toLowerCase() === "pending"
                    ? "#FEF4CB"
                    : data?.status?.toLowerCase() === "cancelled"
                    ? "#F5DEDE"
                    : data?.status?.toLowerCase() === "approved"
                    ? "#C7DBDB"
                    : "#F5DEDE"
                }
                color={
                  data?.status?.toLowerCase() === "pending"
                    ? "#D6B941"
                    : data?.status?.toLowerCase() === "cancelled"
                    ? "#F67373"
                    : data?.status?.toLowerCase() === "approved"
                    ? "#0D6565"
                    : "#F67373"
                }
                borderRadius={"5px"}
              >
                {data?.status}
              </Text>
            </Td>
          </Tooltip>

          <Td>
            <CustomModal
              // icon={AiOutlineEye}
              icon={<AiOutlineEye size="18px" color={"#0D6565"} />}
              header={`Request Details`}
              text="love of gof"
              iconColor={"#154141"}
              data={data}
            >
              {/* <RequestModalContent request={data} /> */}
            </CustomModal>
          </Td>
        </Tr>
      ))}
    </Tbody>
  );
};

export default OverviewTableBody;
