import { Box, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

const Notification = ({ text, bg, color }) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Box
        className={`component ${isVisible ? "visible" : "hidden"}`}

        bg={bg || "tomato"}
        color={color || "white"}
        width="300px"
        p="20px"
        borderRadius="8px"
      >
        <Text>{text}</Text>
      </Box>
    </>
  );
};

export default Notification;
