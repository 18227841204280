import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { sendBroadcast } from "../services/broadcast";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const ContactUsModal = ({ isOpen, onClose }) => {
  const [formValue, setFormValue] = useState({
    title: "",
    message: "",
  });
  // const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  const { mutate: SendBroadcasts, isLoading } = useMutation({
    mutationFn: sendBroadcast,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["broadcast"] });
      onClose();
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = { ...formValue };
    SendBroadcasts(payload);
  };

  return (
    <>
      <Modal size={"2xl"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent px="20px" bg={"#F8F8F8"} w={"100%"}>
          <ModalCloseButton color={"brandColor"} />
          <ModalBody py={6}>
            <Box w={"100%"} bg={"#FFFFFF"} p={"30px"}>
              <Text
                color={"#264653"}
                fontSize={"15px"}
                fontWeight={"semibold"}
                py={"10px"}
              >
                Reason
              </Text>
              <Input
                bg={"#F4F9F9"}
                size={"lg"}
                value={formValue.title}
                onChange={(e) =>
                  setFormValue({ ...formValue, title: e.target.value })
                }
              />
              <Text
                color={"#264653"}
                fontSize={"15px"}
                fontWeight={"semibold"}
                py={"10px"}
                pt={"15px"}
              >
                Message
              </Text>
              <Textarea
                resize={"none"}
                bg={"#F4F9F9"}
                h={"150px"}
                value={formValue.message}
                onChange={(e) =>
                  setFormValue({ ...formValue, message: e.target.value })
                }
              />
              <Flex justify={"center"}>
                <Button
                  bg={"#2A9D8F"}
                  color={"#FFFFFF"}
                  fontSize={"14px"}
                  fontWeight={"light"}
                  alignContent="center"
                  mt={"40px"}
                  px={"60px"}
                  loading={isLoading }
                  _hover={{ bg: "brandColor" }}
                  disabled={!formValue.title || !formValue.message}
                  // isLoading={loading}
                  onClick={handleSubmit}
                  w={["100%", "100%", "60%"]}
                >
                  Proceed
                </Button>
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ContactUsModal;
