import { HStack, Tbody, Td, Text, Tooltip, Tr } from "@chakra-ui/react";
import dayjs from "dayjs";
import { formatToNaira } from "../../../utils/numberFormat";
import { BsArrowUpShort, BsArrowDownShort } from "react-icons/bs";

const DashboardTableBody = ({ data }) => {
  return (
    <Tbody>
      {data?.map((datum) => (
        <Tr key={datum?._id} fontSize={"16px"} maxWidth="170px !important">
          <Tooltip label={datum?.companyId?.fullName}>
            <Td
              isTruncated
              maxWidth="170px !important"
              borderRight="1px solid #dadedf !important"
              cursor="pointer"
            >
              {datum?.companyId?.fullName}
            </Td>
          </Tooltip>
          <Tooltip
            isTruncated
            maxWidth="170px !important"
            label={datum?.category || datum?.requestId?.category?.name || "N/A"}
          >
            <Td
              borderRight="1px solid #dadedf !important"
              maxWidth="170px !important"
            >
              {datum?.category || datum?.requestId?.category?.name || "N/A"}
            </Td>
          </Tooltip>
          <Td
            borderRight="1px solid #dadedf !important"
            maxWidth="170px !important"
          >
            <HStack>
              {datum?.status === "paid" ? (
                <BsArrowUpShort size="20px" color="#0D6565" />
              ) : (
                <BsArrowDownShort size="20px" color="#F67373" />
              )}
              <Text>{formatToNaira(datum?.amount)}</Text>
            </HStack>
          </Td>
          <Tooltip label={dayjs(datum?.createdAt).format("DD MMM YYYY")}>
            <Td
              borderRight="1px solid #dadedf !important"
              width="170px !important"
              isTruncated
            >
              {dayjs(datum?.createdAt).format("DD MMM YYYY")}
            </Td>
          </Tooltip>

          <Td
            color="#C9A721"
            borderRight="1px solid #dadedf !important"
            maxWidth="170px !important"
          >
            <Text
              textAlign={"center"}
              bg={
                datum?.status?.toLowerCase() === "pending"
                  ? "#FEF4CB"
                  : datum?.status?.toLowerCase() === "cancelled"
                  ? "#F5DEDE"
                  : datum?.status?.toLowerCase() === "confirmed"
                  ? "#C7DBDB"
                  : "#F5DEDE"
              }
              color={
                datum?.status?.toLowerCase() === "pending"
                  ? "#D6B941"
                  : datum?.status?.toLowerCase() === "cancelled"
                  ? "#F67373"
                  : datum?.status?.toLowerCase() === "confirmed"
                  ? "#0D6565"
                  : "#F67373"
              }
              p="2px 10px"
              borderRadius={"10px"}
            >
              {datum?.status.toLowerCase() === "confirmed"
                ? "Disbursed"
                : datum?.status}
            </Text>
          </Td>
        </Tr>
      ))}
    </Tbody>
  );
};

export default DashboardTableBody;
