import { errorNotifier, successNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";

export const getSupportTicket = async () => {
  try {
    const data = await http.get(AUTH_ROUTES.GET_SUPPORT_TICKET);
    return data?.data?.data;
  } catch (e) {
    if (e?.response?.data?.message === "Unable to verify token.") {
      errorNotifier("Session timed out");
      window.location.reload();
    }
  }
};

export const responseSupport = async (payload) => {
  try {
    const response = await http.post(
      AUTH_ROUTES.RESPONSE_SUPPORT_TICKET,
      payload
    );
    successNotifier("Support Ticket Responded Successfully");
    return response.data;
  } catch (e) {
    errorNotifier(e.response.data.errors.message);
  }
};

export const resolveTicket = async (payload) => {
  try {
    const response = await http.put(
      AUTH_ROUTES.RESOLVE_TICKET(payload.ticketId),
      payload
    );
    successNotifier("Support Ticket Resolved Successfully");
    return response.data;
  } catch (e) {
    errorNotifier(e.response?.data?.errors?.message);
    throw e;
  }
};
