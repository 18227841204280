import { Box, Heading } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import React from "react";
import { CategoryExpensesCard } from "../components/analytics/CategoryExpensesCard";
import { AverageRevenueCard } from "../components/analytics/AverageRevenueCard";
import { PayinPayoutCard } from "../components/analytics/PayinPayoutCard";
import { TopCompanyCard } from "../components/analytics/TopCompanyCard";
import {
  getDailyCategoryExpense,
  getMonthlyCategoryExpense,
} from "../services/analytics";
import FullPageLoader from "../components/FullPageLoader";

let _COLORS = [
  "#e6194b",
  "#3cb44b",
  "#ffe119",
  "#4363d8",
  "#f58231",
  "#911eb4",
  "#46f0f0",
  "#f032e6",
  "#bcf60c",
  "#fabebe",
  "#008080",
  "#e6beff",
  "#9a6324",
  "#fffac8",
  "#800000",
  "#aaffc3",
  "#808000",
  "#ffd8b1",
  "#000075",
  "#808080",
  "#ffffff",
  "#000000",
];

let haveIt = [];
function generateUniqueRandom() {
  //Generate random number
  let random = (Math.random() * _COLORS.length).toFixed();

  //Coerce to number by boxing
  random = Number(random);

  if (!haveIt.includes(random)) {
    haveIt.push(random);
    return random;
  } else {
    if (haveIt.length < _COLORS.length) {
      //Recursively generate number
      return generateUniqueRandom();
    } else {
      return false;
    }
  }
}

const AnalyticsPage = () => {
  const { data: dailyCategoryExpense, isLoading } = useQuery({
    queryKey: ["dailyCategoryExpense"],
    queryFn: getDailyCategoryExpense,
  });
  const { data: monthlyCategoryExpense, isLoading: loading } = useQuery({
    queryKey: ["monthlyCategoryExpense"],
    queryFn: getMonthlyCategoryExpense,
  });

  return isLoading || loading ? (
    <FullPageLoader />
  ) : (
    <Box>
      <Heading as="h2" fontSize={["22px", "22px", "30px"]}>
        Analytics
      </Heading>
      {/*<ChartRows
        generateUniqueRandom={generateUniqueRandom}
        _COLORS={_COLORS}
        monthlyCategoryExpense={monthlyCategoryExpense}
        dailyCategoryExpense={dailyCategoryExpense}
  />*/}
      <CategoryExpensesCard
        generateUniqueRandom={generateUniqueRandom}
        _COLORS={_COLORS}
        monthlyCategoryExpense={monthlyCategoryExpense}
        dailyCategoryExpense={dailyCategoryExpense}
      />
      <AverageRevenueCard />
      <PayinPayoutCard />
      <TopCompanyCard />
      {/*<ChartRowVendor
        generateUniqueRandom={generateUniqueRandom}
        _COLORS={_COLORS}
        monthlyCategoryExpense={monthlyCategoryExpense}
        dailyCategoryExpense={dailyCategoryExpense}
  />*/}
    </Box>
  );
};
export default AnalyticsPage;
