
import { RiDashboardLine } from "react-icons/ri";
import { BsCashCoin, BsPeople, BsPersonSquare } from "react-icons/bs";
import { BiMessageDetail } from "react-icons/bi";
import { FiSettings } from "react-icons/fi"
import { MdOutlineContactSupport } from "react-icons/md";


export const navList = [
  {
    text: "Overview",
    link: "/",
    icon: RiDashboardLine,
  },

  {
    text: "Companies",
    link: "/companies",
    icon: BsPeople,
  },
  {
    text: "Subscription",
    link: "/subscriptions",
    icon: BsPersonSquare,
  },
  {
    text: "Transactions",
    link: "/transactions",
    icon: BsCashCoin,
  },
  {
    text: "Analytics",
    link: "/analytics",
    icon: BiMessageDetail,
  },
  {
    text: "Account Settings",
    link: "/account-settings",
    icon: FiSettings,
  },
  {
    text: "Support",
    link: "/support",
    icon: MdOutlineContactSupport,
  },
];
