import { errorNotifier, successNotifier } from "..";
import http, { AUTH_ROUTES } from "./api";
import axiosInstance from "./formDataConfig";

export const updateAdminAccount = async (payload) => {
  try {
    await http.put(AUTH_ROUTES.UPDATE_ADMIN_ACCOUNT(payload._id), payload);
    successNotifier("Account successfully updated");
  } catch (e) {
    //(e);
    errorNotifier(e?.response?.data?.message);
  }
};
export const uploadImage = async (payload, setRefresh) => {
  try {
    await axiosInstance.post(AUTH_ROUTES.UPLOAD_IMAGE, payload);
    successNotifier("Image successfully updated");
    setRefresh((prev) => !prev);
  } catch (e) {}
};
